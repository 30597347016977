/**
 * Thank you page
 */
/* Import section */
import * as React from "react"
import { graphql } from "gatsby"
import Geturlparameter from "../components/geturlparameter"
import Herotype2 from "../components/herotype2"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ThankyouPage = ({ data, search }) => {
  //const { job } = search
  //Const data
  const dataPage = data.wpPage

  return(
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
         />
      <section className="section-hero-general section-herotype2">
        <Herotype2 h1title={dataPage.title} />
      </section>
      <section>
        <div className="wrap-centercontent">
          <div className="centercontent general" dangerouslySetInnerHTML={{__html:dataPage.content}} />
        </div>
      </section>
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpPage(slug: {regex: "/thank-you/"}, language: {slug: {eq: $language}}) {
      title
      content
      seo {
        metaDesc
        title
      }
    }
  }
`

export default Geturlparameter(ThankyouPage)
